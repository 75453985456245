@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

html {
    font-family: "Inter", sans-serif;
    font-size: 18px;
    color: #000000;
    background-color: #FFFFFF;
}

body {
    overflow: auto;
}

* {
    box-sizing: border-box;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

*:focus {
    outline: none;
}

a {
    font-weight: 400;
    font-size: inherit;
    color: #386BD9;
    text-decoration: none;
}

a:hover,
a:active {
    text-decoration: underline;
}

.not-scrolling {
    overflow: hidden;
}
